import {Bookmark} from "./model";
import {useEffect, useState} from "react";
import {supabase} from "./client";
import {Session} from "@supabase/supabase-js";

export function BookmarkList (props:{ bookmarks:Bookmark[], session:Session}) {
    const [loading, setLoading] = useState(true)
    const [username, setUsername] = useState(null)
    useEffect(() => {
        let ignore = false
        async function getProfile() {
            setLoading(true)
            const { user } = props.session

            const { data, error } = await supabase
                .from('profiles')
                .select(`username, website, avatar_url`)
                .eq('id', user.id)
                .single()

            if (!ignore) {
                if (error) {
                    console.warn(error)
                } else if (data) {
                    setUsername(data.username)
                    // setWebsite(data.website)
                    // setAvatarUrl(data.avatar_url)
                }
            }

            setLoading(false)
        }


        getProfile()


        return () => {
            ignore = true
        }
    }, [props.session])

    return <div>
        <h3>{username}</h3>
        <ul>
        {
            props.bookmarks.map((bk,i) => {
                return <li key={i}><a href={bk.url} target={"_blank"}>{bk.name}</a></li>
            })
        }
        </ul>
    </div>
}
