import React, {useEffect, useState} from 'react';
import './App.css';
import Auth from './Auth'
import {supabase} from './client'

import {Session} from "@supabase/supabase-js";
import {Main} from "./main";

function LogoutButton(props: { session: Session }) {
    const [username, setUsername] = useState("")
    async function getProfile() {
        const { user } = props.session
        const { data, error } = await supabase
            .from('profiles')
            .select(`username, website, avatar_url`)
            .eq('id', user.id)
            .single()
        if (error) {
            console.warn(error)
        } else if (data) {
            setUsername(data.username)
        }
    }

    useEffect(() => {
        getProfile()
    }, [props.session]);
    return <div>
        <h3>your {username}</h3>
        <button onClick={() => {
            console.log("logging out")
            supabase.auth.signOut()
        }}>log out</button>
    </div>
}

function App() {
    const [session, setSession] = useState<Session|null>(null)
    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
    }, [])

    return (
        <div className="container" style={{ padding: '50px 0 100px 0' }}>
            {!session ? <Auth /> : <div>
                <LogoutButton session={session}/>
                <Main session={session}/>
            </div> }
        </div>
    )
}

export default App;
