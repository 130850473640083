import {useState} from "react";
import {Bookmark} from "./model";
import {Session} from "@supabase/supabase-js";
import {supabase} from "./client";


export function AddBookmarkForm(props:{ session:Session, onChanged:() => void}) {
    const [url, setUrl] = useState("")
    const [name, setName] = useState("")

    return <div className={'bookmark-form'}>

        <label htmlFor={"url"}>url</label>
        <input type={"text"} name={'url'} value={url} onChange={e => setUrl(e.target.value)}/>

        <label htmlFor={"name"}>name</label>
        <input type={"text"} name={'name'} value={name} onChange={e => setName(e.target.value)}/>

        <button onClick={async () => {
            console.log('adding', url, name)
            const { user } = props.session
            const {data, error} = await supabase.from("bookmarks").insert({
                name:name,
                url:url,
                user_id:user.id
            })
            console.log("error",error)
            console.log('data', data)
            props.onChanged()
        }}>add
        </button>

    </div>
}
