import {Session} from "@supabase/supabase-js";
import {BookmarkList} from "./bookmarklist";
import React, {useEffect, useState} from "react";
import {AddBookmarkForm} from "./AddBookmarkForm";
import {supabase} from "./client";
import {Bookmark} from "./model";

export function Main(props: { session: Session }) {
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([])

    async function getBookmarks() {
        const {user } = props.session
        const {data, error} = await supabase
            .from('bookmarks')
            .select('name, url, created_at')
        if(error) console.warn(error)
        console.log("bookmarks",data)
        if(data) setBookmarks(data as unknown as Bookmark[])
    }

    useEffect(() => {
        getBookmarks()
    }, [props.session]);

    return <div>
        <AddBookmarkForm session={props.session} onChanged={() => getBookmarks()} />
        <BookmarkList bookmarks={bookmarks} session={props.session}/>
    </div>
}
